import React from 'react';
import Navbar from './Navbar';
import Contact from './Contact';
import Research from './Research';
import Group from './Group';
import Publications from './Publications';
import About from './About';
import Software from './Software';
import Talks from './Talks';
import Teaching from './Teaching';
import Awards from './Awards';
import Service from './Service';
import Intro from './Intro';
import AnaPortrait from './AnaPortrait';
import easl_logo from './easl_logo.png';
import './App.css';

const headerBgStyle = {
   
};

function App() {
  return (
    <div>
      <div className="container-fluid pt-4" id="Home"></div>
      <Navbar />
      <div className="container-fluid">
        <div className="row justify-content-center pt-4 pb-4 mb-4" id="Banner">
          <h1 className="display-4" style={{textShadow: "1px 1px #000000"}}>Ana Klimović</h1>
        </div>
      </div>
      
      <div className="container"> 
        <div className="row justify-content-center">
          <div className="col-3 d-none d-md-block pt-4 mr-3" >
            <div className="row justify-content-end pr-3 pl-3 pb-2">
              <AnaPortrait />
            </div>
            <div className="row pr-3 pl-3"><Contact /></div>
          </div>

          <div className="col">   
            <div className="row pr-3 pl-3">
              <div className="d-flex col-6 pt-4 p-0 d-md-none justify-content-center align-items-center">
                <AnaPortrait />
              </div>
              <div className="col-6 d-md-none pt-4"><Contact /></div>
            </div>
            <div className="row pr-3 pl-3 pt-4 ">
              <Intro />
            </div>
          </div>
        </div>

        <div id="Research" className="row justify-content-start pl-3 pr-3"><h2>Research Focus Areas</h2></div>
        <div className="row justify-content-center pl-3 pr-3"><Research /></div>

        <div id="Group" className="row justify-content-start pl-3 pr-3"><h2><img className="img-fluid pl-3" src={easl_logo} style={{height:"120px"}}/> Research Group</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Group /></div>

        <div id="Publications" className="row justify-content-start pl-3 pr-3"><h2>Publications</h2></div>
        <div className="row justify-content-center pl-3 pr-3"><Publications /></div>
        
        <div id="Selected Talks" className="row justify-content-start pl-3 pr-3"><h2>Talks</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Talks /></div>

        <div id="Software" className="row justify-content-start pl-3 pr-3"><h2>Open Source Software</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Software /></div>

        <div id="Teaching" className="row justify-content-start pl-3 pr-3"><h2>Teaching</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Teaching /></div>

        <div id="Awards" className="row justify-content-start pl-3 pr-3"><h2>Awards</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Awards /></div>

        <div id="Service" className="row justify-content-start pl-3 pr-3"><h2>Service</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><Service /></div>
        
        <div id="About" className="row justify-content-start pl-3 pr-3"><h2>About Me</h2></div>
        <div className="row justify-content-start pl-3 pr-3"><About /></div>

        <div className="row justify-content-center pl-3 pr-3 pb-5">
          <a className="text-muted" style={{fontSize:'10pt'}} href="https://www.freepik.com/free-photos-vectors/background">Background banner created by freepik.com</a>
        </div>
      </div>
    </div>
  );
}

export default App;
