import React from 'react';

function Teaching() {
  return (
    <div>
      <br />
       ETH Zurich: 
      <ul>
        <li><b><a href="https://systems.ethz.ch/education/courses/2024-autumn-semester/systems-programming-and-computer-architecture-.html">Systems Programming and Computer Architecture</a></b>, Fall 2020, 2021, 2022, 2023, 2024, co-taught with <a href="https://people.inf.ethz.ch/troscoe">Prof. Timothy Roscoe</a></li>
        <li><b><a href="https://systems.ethz.ch/education/courses/2024-spring/cloud-computing-architecture.html">Cloud Computing Architecture</a></b>, Spring 2021, 2022, 2023, 2024, 2025, co-taught with <a href="https://people.inf.ethz.ch/alonso/alonso_page.html">Prof. Gustavo Alonso</a> in 2021 - 2023</li>
        <li><b><a href="https://systems.ethz.ch/education/courses/2023-autumn-semester/seminar-on-machine-learning-systems.html">Seminar on Machine Learning Systems</a></b>, Fall 2021, 2022, 2023, 2024, co-taught with <a href="https://zhangce.github.io/">Prof. Ce Zhang</a> in 2021, 2022</li>
        <li><b><a href="https://systems.ethz.ch/education/courses/2020-fall/hardware-acceleration-for-data-processing.html">Hardware Acceleration for Data Processing (Seminar)</a></b>, Fall 2020, co-taught with <a href="https://people.inf.ethz.ch/alonso/alonso_page.html">Prof. Gustavo Alonso</a> and <a href="https://ds3lab.inf.ethz.ch/members/ce-zhang.html">Prof. Ce Zhang</a></li>
        <li><b><a href="http://www.vvz.ethz.ch/lerneinheitPre.do?semkez=2020W&lerneinheitId=140129&lang=en">Distributed Systems Laboratory</a></b>, Fall/Spring 2020 - 2024</li>
     </ul>
       Stanford University:
     <ul> 
        <li><b><a href="https://online.stanford.edu/courses/ee282-computer-systems-architecture">Computer Systems Architecture</a></b>, Winter 2019, co-taught with <a href="https://hennessy.stanford.edu/biography">Prof. John L. Hennessy</a> and <a href="https://cs.stanford.edu/~neeraja">Neeraja Yadwadkar</a></li>
      </ul>
      <br />
    </div>
  );
}

export default Teaching;
