import React from 'react';
import systems_logo from './tiny-systems.png';
import eth_logo from './tiny-ethlogo.png';

function Contact() {
  let url = process.env.PUBLIC_URL+"/AnaKlimovic_CV.pdf";
  return (
    <div><p>
      <a href="mailto:aklimovic@ethz.ch"><b>aklimovic@ethz.ch</b></a><br />
      <a href={url} target= "_blank" rel="noopener noreferrer">CV</a> | 
      <a href="https://scholar.google.com/citations?user=i7jievkAAAAJ&hl=en" target= "_blank" rel="noopener noreferrer"> Google Scholar</a> | 
      <a href="https://twitter.com/anaklimovic" target= "_blank" rel="noopener noreferrer"> Twitter</a>
    </p>
    </div>
  );
}

export default Contact;
