import React, { Component } from 'react';
import eth_logo from './tiny-ethlogo.png';
import systems_logo from './systems_logo.png';
import easl_logo from './easl_logo.png';

class TopNavbar extends Component {
  constructor(props) {
    super(props);
    this.collapseNavbar = this.collapseNavbar.bind(this);
    this.state = {
    };
    this.collapseRef = React.createRef();
  }

  collapseNavbar(){
    if (this.collapseRef){
      this.collapseRef.current.classList.toggle('show');
    }
  }

  render(){
    return (
      <nav className="navbar navbar-light sticky-top navbar-expand-md border-bottom border-light bg-white shadow-sm" 
       data-spy="affix" data-offset-top="400" id="navbar-top">
        <a className="navbar-brand"></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" 
          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent" ref={this.collapseRef}>
          <a href="https://ethz.ch/en.html" target="_blank" rel="noopener noreferrer"><img className="img-fluid pl-3" src={eth_logo} style={{height:"35px"}}/></a>
          <a href="https://www.systems.ethz.ch/" target="_blank" rel="noopener noreferrer"><img className="img-fluid pl-3" src={systems_logo} style={{height:"45px"}}/></a>
          <a href="https://www.systems.ethz.ch/research/easl" target="_blank" rel="noopener noreferrer"><img className="img-fluid pl-3" src={easl_logo} style={{height:"45px"}}/></a>
          <a className="nav-link" href="#" onClick={()=>{this.collapseNavbar();window.scrollTo(0, 0)}}>Home</a>
          <a className="nav-link" href="#Research" onClick={this.collapseNavbar}>Research</a>
          <a className="nav-link" href="#Group" onClick={this.collapseNavbar}>Group</a>
          <a className="nav-link" href="#Publications" onClick={this.collapseNavbar}>Publications</a>
          <a className="nav-link" href="#Talks" onClick={this.collapseNavbar}>Talks</a>
          <a className="nav-link" href="#Software" onClick={this.collapseNavbar}>Code</a>
          <a className="nav-link" href="#Teaching" onClick={this.collapseNavbar}>Teaching</a>
          <a className="nav-link" href="#Awards" onClick={this.collapseNavbar}>Awards</a>
          <a className="nav-link" href="#About" onClick={this.collapseNavbar}>About Me</a>
        </div>
      </nav>
    );
  }
}

export default TopNavbar;
