import React from 'react';

function Awards() {
  return (
    <div>
      <br />
       As a professor: 
      <ul>
        <li><a href="https://research.google/programs-and-events/google-academic-research-awards/google-academic-research-award-program-recipients/" target= "_blank" rel="noopener noreferrer">Google Academic Research Award</a>, 2024</li>
        <li>VMware Early-Career Faculty Award, 2023</li>
        <li><a href="https://inf.ethz.ch/news-and-events/spotlights/infk-news-channel/2023/11/snsf-starting-grant-2023.html" target= "_blank" rel="noopener noreferrer">Swiss National Science Foundation Starting Grant</a>, 2023</li>
        <li><a href="https://research.facebook.com/blog/2021/09/announcing-the-winners-of-the-2021-next-generation-data-infrastructure-request-for-proposals/" target= "_blank" rel="noopener noreferrer">Facebook Research Award</a>, 2021</li>
        <li>Google-Initiated Faculty Research Award, 2020</li>
      </ul>
      
       As a student:
      <ul>
        <li><a href="http://nvmw.ucsd.edu/2019/2017/10/27/nvmw-memorable-paper-award/" target= "_blank" rel="noopener noreferrer">Memorable Paper Award</a> for <a href="https://anakli.inf.ethz.ch/papers/reflex.pdf"><i>ReFlex</i></a>, 2018</li>
        <li><a href="https://stvp.stanford.edu/alp/" target= "_blank" rel="noopener noreferrer">Accel Innovation Scholar</a>, 2018</li>
        <li><a href="https://www.microsoft.com/en-us/research/academic-program/phd-fellowship/" target="_blank" rel="noopener noreferrer">Microsoft Research Fellowship</a>, 2015 - 2017</li>
        <li><a href="https://www.usenix.org/conference/osdi14/technical-sessions" target= "_blank" rel="noopener noreferrer">Best Paper Award</a> for <a href="https://anakli.inf.ethz.ch/papers/ix-osdi2014.pdf"><i>IX</i></a>, 2014</li>
        <li><a href="https://vpge.stanford.edu/fellowships-funding/sgf/details" target= "_blank" rel="noopener noreferrer">Stanford Graduate Fellowship</a>, 2013 - 2016</li>
        <li>W.S. Wilson Medal in Engineering Science, 2013</li>
        <li>University of Toronto Galbraith Scholar, 2009</li>
        <li><a href="https://www.gg.ca/en/honours/governor-generals-awards/governor-generals-academic-medal" target= "_blank" rel="noopener noreferrer">Canadian Governor General Academic Medal</a>, 2009</li>
      </ul>
       
      Awards that my students have received: 
      <ul>
        <li><a href="https://fotstrt.github.io/" target= "_blank" rel="noopener noreferrer">Foteini Strati</a> received the <a href="https://ethz.ch/en/the-eth-zurich/education/awards/eth-medal/outstanding-master-theses.html" target= "_blank" rel="noopener noreferrer">ETH Medal for Outstanding Master's Thesis</a> (2022) and the <a href="https://mlcommons.org/about-us/programs/" target= "_blank" rel="noopener noreferrer">ML and Systems Rising Star Award</a> (2024)</li>
        <li><a href="https://www.linkedin.com/in/muyu-li/" target= "_blank" rel="noopener noreferrer">Muyu Li</a> received the <a href="https://www.kutter-fonds.ethz.ch/en/contest/" target= "_blank" rel="noopener noreferrer">Fritz Kutter Award</a> for his Master's thesis, 2022</li>
      </ul>
      <br />
    </div>
  );
}

export default Awards;
